import { normalizedNumber } from "../../helpers/utils"

export function StatisticsEntry(
        {
            columns
        }: {
            columns: (ColumnEntry | FinancialColumnEntry)[]
        }
) {

    return <tr>
        {columns.map(m => <TdColumn column={m}/>)}
    </tr>
}

export function TdColumn(
    {
        column
    }: {
        column: ColumnEntry | FinancialColumnEntry
    }
) {
    switch(column.type) {
        case 'Plain':
            return <td className={column.className ?? ""}>{column.value.toLocaleString()}</td>
        case 'Financial':
            let color = "lightgray";
            if (column.delta > 0) {
                color = 'green';
            } else if (column.delta < 0) {
                color = 'red';
            }
            return <td className={column.className ?? ""}>{normalizedNumber(column.value).toLocaleString()} <span className="" style={{color:color}}>({normalizedNumber(column.delta).toLocaleString()})</span></td>
        default:
            return <></>
    }

}

export interface ColumnEntry {
    type: 'Plain'
    value: any
    className ?: string
}

export interface FinancialColumnEntry {
    type: 'Financial'
    value: number
    delta: number
    className ?: string
}
