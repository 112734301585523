import './Statistics.css'
import { Table } from "reactstrap"
import {useGetStatisticsQuery} from "../../backoffice/apis/statisticsAPI";
import { DateRange } from '../../helpers/Types';
import {StatisticsEntry} from "./StatisticsEntry";
import { normalizedNumber } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';
import { PromotionCriteria } from '../../backoffice/dtos/product/promotionCriteria';


export default function StatisticsBody(
        {
            promotionCriteria
        } : {
            promotionCriteria: PromotionCriteria
        }
) {
    const { data: statisticsModel, isLoading: areStatisticsLoading } = useGetStatisticsQuery(promotionCriteria, {refetchOnMountOrArgChange: true})
    
    const {t} = useTranslation(['statistics'])
    if (!statisticsModel)
        return <></>

    return <div className="p-3">
        
        <Table>
            <thead>
                <tr>
                    <th className="text-align-center">Top 5</th>
                </tr>
            </thead>
        </Table>
        <Table striped hover>
            <thead>
                <tr>
                    <th className="text-align-center">{t('brand')}</th>
                    <th className="text-align-center">{t('cider')} (Hls)</th>
                    <th className="text-align-center">{t('cider')} (€)</th>
                    <th className="text-align-center">{t('beer')} (Hls)</th>
                    <th className="text-align-center">{t('beer')} (€)</th>
                </tr>
            </thead>
            <tbody>
                {statisticsModel.topCustomersDetails.map(m =>
                <StatisticsEntry columns={[
                    { type: 'Plain', value: m.company },
                    { type: 'Financial', value: (m.ciderHls), delta: m.ciderHlsDelta, className: "text-align-end" },
                    { type: 'Financial', value: (m.ciderEuro), delta: m.ciderEuroDelta,  className: "text-align-end"},
                    { type: 'Financial', value: (m.beerHls), delta: m.beerHlsDelta,  className: "text-align-end"},
                    { type: 'Financial', value: (m.beerEuro), delta: m.beerEuroDelta, className: "text-align-end"}
                ]}/>)}
                <StatisticsEntry columns={[
                    { type: 'Plain', value: t('total'), className: "strong"},
                    { type: 'Financial', delta: statisticsModel.topCustomers.ciderHlsDelta,  value: (statisticsModel.topCustomers.ciderHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.topCustomers.ciderEuroDelta,  value: (statisticsModel.topCustomers.ciderEuro), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.topCustomers.beerHlsDelta,  value: (statisticsModel.topCustomers.beerHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.topCustomers.beerEuroDelta,  value: (statisticsModel.topCustomers.beerEuro), className: "text-align-end"}
                ]}/>
            </tbody>
        </Table>

        <br/>
        <Table>
            <thead>
                <tr>
                    <th className="text-align-center">Other</th>
                </tr>
            </thead>
        </Table>
        <Table striped hover>
            <thead>
                <tr>
                    <th className="text-align-center">{t('brand')}</th>
                    <th className="text-align-center">{t('cider')} (Hls)</th>
                    <th className="text-align-center">{t('cider')} (€)</th>
                    <th className="text-align-center">{t('beer')} (Hls)</th>
                    <th className="text-align-center">{t('beer')} (€)</th>
                </tr>
            </thead>
            <tbody>
                {statisticsModel.otherCustomersDetails.map(m =>
                <StatisticsEntry columns={[
                    { type: 'Plain', value: m.company },
                    { type: 'Financial', delta: m.ciderHlsDelta, value: (m.ciderHls), className: "text-align-end" },
                    { type: 'Financial', delta: m.ciderEuroDelta, value: (m.ciderEuro), className: "text-align-end"},
                    { type: 'Financial', delta: m.beerHlsDelta, value: (m.beerHls), className: "text-align-end"},
                    { type: 'Financial', delta: m.beerEuroDelta, value: (m.beerEuro), className: "text-align-end"}
                ]}/>)}
                <StatisticsEntry columns={[
                    { type: 'Plain', value: t('total')},
                    { type: 'Financial', delta: statisticsModel.otherCustomers.ciderHlsDelta, value: (statisticsModel.otherCustomers.ciderHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.otherCustomers.ciderEuroDelta, value: (statisticsModel.otherCustomers.ciderEuro), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.otherCustomers.beerHlsDelta, value: (statisticsModel.otherCustomers.beerHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.otherCustomers.beerEuroDelta, value: (statisticsModel.otherCustomers.beerEuro), className: "text-align-end"}
                ]}/>
            </tbody>
        </Table>
        <br/>
        <Table>
            <thead>
                <tr>
                    <th className="text-align-center">Totals</th>
                </tr>
            </thead>
        </Table>
        <Table striped hover>
            <thead>
                <tr>
                    <th className="text-align-center">{t('brand')}</th>
                    <th className="text-align-center">{t('cider')} (Hls)</th>
                    <th className="text-align-center">{t('cider')} (€)</th>
                    <th className="text-align-center">{t('beer')} (Hls)</th>
                    <th className="text-align-center">{t('beer')} (€)</th>
                </tr>
            </thead>
            <tbody>
                <StatisticsEntry columns={[
                    { type: 'Plain', value: "Top 5"},
                    { type: 'Financial', delta: statisticsModel.topCustomers.ciderHlsDelta,  value: (statisticsModel.topCustomers.ciderHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.topCustomers.ciderEuroDelta, value: (statisticsModel.topCustomers.ciderEuro), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.topCustomers.beerHlsDelta,   value: (statisticsModel.topCustomers.beerHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.topCustomers.beerEuroDelta,  value: (statisticsModel.topCustomers.beerEuro), className: "text-align-end"}
                ]}/>
                <StatisticsEntry columns={[
                    { type: 'Plain', value: "Others"},
                    { type: 'Financial', delta: statisticsModel.otherCustomers.ciderHlsDelta,   value: (statisticsModel.otherCustomers.ciderHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.otherCustomers.ciderEuroDelta,  value: (statisticsModel.otherCustomers.ciderEuro), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.otherCustomers.beerHlsDelta,    value: (statisticsModel.otherCustomers.beerHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.otherCustomers.beerEuroDelta,   value: (statisticsModel.otherCustomers.beerEuro), className: "text-align-end"}
                ]}/>
                <StatisticsEntry columns={[
                    { type: 'Plain', value: t('total')},
                    { type: 'Financial', delta: statisticsModel.allCustomers.ciderHlsDelta,   value: (statisticsModel.allCustomers.ciderHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.allCustomers.ciderEuroDelta,  value: (statisticsModel.allCustomers.ciderEuro), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.allCustomers.beerHlsDelta,    value: (statisticsModel.allCustomers.beerHls), className: "text-align-end"},
                    { type: 'Financial', delta: statisticsModel.allCustomers.beerEuroDelta,   value: (statisticsModel.allCustomers.beerEuro), className: "text-align-end"}
                ]}/>
            </tbody>
        </Table>
    </div>
}
